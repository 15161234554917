import React, { Suspense } from 'react';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import { Object3DNode, useFrame } from 'react-three-fiber';
import useMultiKeyPress from '../hooks/useMultiKeyPress';
import Fnutt from './Fnutt';

const keys = {
  movement: {
    up: ['ArrowUp', 'w'],
    down: ['ArrowDown', 's'],
    left: ['ArrowLeft', 'a'],
    right: ['ArrowRight', 'd'],
  },
  jump: [' '],
};

let speed = 0.06;

interface PlayerFnuttProps {
  position: { x: number; y: number; z: number };
  updatePosition: (position: { x: number; y: number; z: number }) => void;
  name: string;
  move: IJoystickUpdateEvent;
}

export default function PlayerFnutt(props: PlayerFnuttProps) {
  const mesh = React.useRef<Object3DNode<any, any>>();
  const keysPressed = useMultiKeyPress();
  const [jumpAmount, setJumpAmount] = React.useState(-1);

  useFrame(() => {
    let update = false;
    if (
      mesh.current &&
      Array.from(keysPressed).some((k) =>
        Object.values(keys.movement).flat().includes(k)
      )
    ) {
      speed += 0.0001;
      if (speed > 0.6) {
        speed = 0.6;
      }
      // move up
      if (Array.from(keysPressed).some((k) => keys.movement.up.includes(k))) {
        mesh.current!.position!.z -= speed;
      }
      // move down
      if (Array.from(keysPressed).some((k) => keys.movement.down.includes(k))) {
        mesh.current!.position!.z += speed;
      }
      // move left
      if (Array.from(keysPressed).some((k) => keys.movement.left.includes(k))) {
        mesh.current.position!.x -= speed;
      }
      // move right
      if (
        Array.from(keysPressed).some((k) => keys.movement.right.includes(k))
      ) {
        mesh.current!.position!.x += speed;
      }

      update = true;
    } else {
      speed = 0.06;
    }

    // jump
    if (
      mesh.current &&
      (mesh.current!.position.y > 0.01 ||
        Array.from(keysPressed).some((k) => keys.jump.includes(k)))
    ) {
      const amount = jumpAmount + 0.15;

      setJumpAmount(amount);
      mesh.current!.position.y = (Math.sin(amount) + 1) / 5;

      // reset jump
      if (
        amount < 0.01 &&
        !Array.from(keysPressed).some((k) => keys.jump.includes(k))
      ) {
        setJumpAmount(-1);
        mesh.current!.position.y = 0;
      }

      update = true;
    }

    // joystick
    if (props.move?.direction != null) {
      mesh.current!.position.z -= props.move.y / 600;
      mesh.current!.position.x += props.move.x / 600;
      update = true;
    }

    if (update) {
      if (mesh.current!.position!.z < 1.2) {
        mesh.current!.position!.z = 1.2;
      } else if (mesh.current!.position!.z > 11.1) {
        mesh.current!.position!.z = 11.1;
      }

      if (mesh.current!.position!.x > 6.8) {
        mesh.current!.position!.x = 6.8;
      } else if (mesh.current!.position!.x < -6.8) {
        mesh.current!.position!.x = -6.8;
      }

      props.updatePosition({
        x: mesh.current!.position!.getComponent(0),
        y: mesh.current!.position!.getComponent(1),
        z: mesh.current!.position!.getComponent(2),
      });
    }
  });

  function Box() {
    return (
      <mesh>
        <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
        <meshStandardMaterial attach="material" transparent opacity={0.5} />
      </mesh>
    );
  }

  return (
    <mesh ref={mesh} position={[0, 0, 4]}>
      <Suspense fallback={<Box />}>
        <Fnutt name={props.name} jumpAmount={props.position.y} />
      </Suspense>
    </mesh>
  );
}
