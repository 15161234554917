import { useState, useEffect } from 'react';

export default function useMultiKeyPress() {
  const [keysPressed, setKeyPressed] = useState(new Set<string>([]));

  useEffect(() => {
    function downHandler({ key }) {
      setKeyPressed((keysPressed) => keysPressed.add(key));
    }

    const upHandler = ({ key }) => {
      setKeyPressed((keysPressed) => {
        keysPressed.delete(key);
        return keysPressed;
      });
    };

    const windowBlur = () => {
      setKeyPressed(new Set<string>([]));
    };

    window.addEventListener('blur', windowBlur);
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('blur', windowBlur);
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keysPressed;
}
