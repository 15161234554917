import React, { Suspense } from 'react';
import Fnutt from './Fnutt';

interface FriendFnuttProps {
  position: { x: number; y: number; z: number };
  name: string;
}

export default function FriendFnutt(props: FriendFnuttProps) {
  function Box() {
    return (
      <mesh>
        <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
        <meshStandardMaterial attach="material" transparent opacity={0.5} />
      </mesh>
    );
  }

  return (
    <mesh position={[props.position.x, props.position.y, props.position.z]}>
      <Suspense fallback={<Box />}>
        <Fnutt name={props.name} jumpAmount={props.position.y} />
      </Suspense>
    </mesh>
  );
}
