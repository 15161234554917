import React, { Suspense } from 'react';
import { Joystick } from 'react-joystick-component';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import { Canvas } from 'react-three-fiber';
import { io, Socket } from 'socket.io-client';
import FriendFnutt from './components/FriendFnutt';
import ImageSlide from './components/ImageSlide';
import PlayerFnutt from './components/PlayerFnutt';

function ShadowPlane({ ...props }) {
  return (
    <mesh {...props} receiveShadow>
      <planeBufferGeometry args={[14, 14]} />
      <shadowMaterial transparent opacity={0.1} />
    </mesh>
  );
}

function App() {
  const socketRef = React.useRef<Socket>();
  const inputNameRef = React.useRef<HTMLInputElement>();
  const [name, setName] = React.useState('');
  const [imageSlideIndex, setTmageSlideIndex] = React.useState(0);
  const [joystickMove, setJoystickMove] = React.useState<IJoystickUpdateEvent>(
    null
  );

  const [fnuttPositions, setFnuttPositions] = React.useState<
    Record<string, { x: number; y: number; z: number }>
  >({});
  const [fnuttNames, setFnuttNames] = React.useState<Record<string, string>>(
    {}
  );

  React.useEffect(() => {
    if (!name) {
      return;
    }
    // Creates a WebSocket connection
    socketRef.current = io(process.env.REACT_APP_WS_URL, {
      transports: ['websocket'],
    });

    socketRef.current?.emit('name', name);

    socketRef.current.on(
      'init',
      ({ fnuttPositions, fnuttNames, currentSlideIndex }) => {
        setFnuttPositions(fnuttPositions);
        setFnuttNames(fnuttNames);
        setTmageSlideIndex(currentSlideIndex);
      }
    );

    socketRef.current.on('slide', (index: number) => {
      setTmageSlideIndex(index);
    });

    socketRef.current.on('update', (changes: Record<string, any>) => {
      setFnuttPositions((f) => {
        return { ...f, ...changes };
      });
    });

    socketRef.current.on('name', (id: string, name: string) => {
      setFnuttNames((n) => {
        var newNames = { ...n };
        newNames[id] = name;
        return newNames;
      });
    });

    socketRef.current.on('remove', (id: string) => {
      setFnuttPositions((f) => {
        var newFnutts = { ...f };
        delete newFnutts[id];
        return newFnutts;
      });
    });

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      socketRef.current?.disconnect();
    };
  }, [name]);

  const updatePosition = React.useCallback((position: any) => {
    socketRef.current?.emit('position', position);
  }, []);

  const updateImageSlide = React.useCallback((index: number) => {
    socketRef.current?.emit('slide', index);
  }, []);

  return name === '' ? (
    <main className="name-select">
      <img className="logo" src="/logo.svg" alt="Itiden logo" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setName(inputNameRef.current.value);
        }}
      >
        <input
          ref={inputNameRef}
          type="text"
          name="name"
          required
          maxLength={12}
          placeholder="Ditt namn"
        />
        <button type="submit" className="btn">
          Välkommen in
        </button>
      </form>
    </main>
  ) : (
    <>
      <div className="joystick">
        <Joystick
          size={80}
          baseColor="rgb(50,50,50)"
          stickColor="rgb(80,80,80)"
          move={(e) => setJoystickMove(e)}
          stop={(e) => setJoystickMove(null)}
        ></Joystick>
      </div>
      <Canvas shadowMap camera={{ position: [0, 3, 14] }}>
        <ambientLight />
        <pointLight castShadow position={[0, 20, -12]} />
        {Object.entries(fnuttPositions).map(([key, value]) =>
          key === socketRef?.current?.id ? (
            <PlayerFnutt
              key={key}
              updatePosition={updatePosition}
              position={value}
              name={name}
              move={joystickMove}
            />
          ) : (
            <FriendFnutt key={key} position={value} name={fnuttNames[key]} />
          )
        )}
        <mesh position={[0, 0, 8]} rotation={[-0.5 * Math.PI, 0, 0]}>
          <planeBufferGeometry args={[14, 14]} />
          <meshBasicMaterial color="#333333" />
        </mesh>
        <mesh>
          <Suspense fallback={null}>
            <ImageSlide
              admin={name === 'grymtflow'}
              imageSlideIndex={imageSlideIndex}
              updateImageSlide={updateImageSlide}
            />
          </Suspense>
        </mesh>
        <ShadowPlane
          rotation={[-0.5 * Math.PI, 0, 0]}
          position={[0, 0.01, 8]}
        />
      </Canvas>
    </>
  );
}

export default App;
