import React from 'react';
import { useLoader } from 'react-three-fiber';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import RobotoFont from '../assets/roboto.json';

// load in font
const font = new THREE.FontLoader().parse(RobotoFont);

// configure font mesh
const textOptions = {
  font,
  size: 0.6,
  textAlign: 'center',
  height: 0,
};

interface FnuttProps {
  name: string;
  jumpAmount: number;
}

export default function Fnutt({ name, jumpAmount }: FnuttProps) {
  const { scene } = useLoader(GLTFLoader, './fnutt.gltf');
  const copiedScene = React.useMemo(() => {
    const newScene = scene.clone();
    newScene.traverse(function (node) {
      if (node.isMesh) {
        node.castShadow = true;
      }
    });
    return newScene;
  }, [scene]);

  const fixedName = name === 'grymtflow' ? 'Itiden' : name ?? '...';

  return (
    <mesh scale={[0.3, 0.3]} castShadow>
      {copiedScene != null && (
        <mesh
          scale={[1 - jumpAmount / 3, 1 + jumpAmount / 3]}
          position={[0, 0, 0]}
        >
          <primitive object={copiedScene} />
        </mesh>
      )}
      <mesh
        castShadow
        position={[-(fixedName.length ?? 1) / 5, 3 + jumpAmount * 1.5, -0.1]}
      >
        <textGeometry attach="geometry" args={[fixedName, textOptions]} />
      </mesh>
    </mesh>
  );
}
