import React from 'react';
import { useLoader } from 'react-three-fiber';
import * as THREE from 'three';
import { useWindowSize } from '../hooks/useWindowSize';

interface ImageSlideProps {
  admin: boolean;
  imageSlideIndex: number;
  updateImageSlide: (index: number) => void;
}

export default function ImageSlide({
  admin,
  imageSlideIndex,
  updateImageSlide,
}: ImageSlideProps) {
  const texture1 = useLoader(THREE.TextureLoader, './logo.png');
  const texture2 = useLoader(THREE.TextureLoader, './map.png');
  const texture3 = useLoader(THREE.TextureLoader, './services.png');
  const texture4 = useLoader(THREE.TextureLoader, './icons.png');
  const texture5 = useLoader(THREE.TextureLoader, './clients.png');
  const texture6 = useLoader(THREE.TextureLoader, './mer.jpg');

  const images = React.useMemo(
    () => [texture1, texture2, texture3, texture4, texture5, texture6],
    []
  );

  const windowSize = useWindowSize();

  const [selectedTexture, setSelectedTexture] = React.useState(texture1);

  React.useEffect(() => {
    setSelectedTexture(images[imageSlideIndex]);
  }, [imageSlideIndex, images]);

  React.useEffect(() => {
    function downHandler({ key }) {
      if (!admin) {
        return;
      }
      if (key === 'n') {
        let newIndex = imageSlideIndex + 1;
        if (newIndex >= images.length) newIndex = 0;
        updateImageSlide(newIndex);
      } else if (key === 'b') {
        let newIndex = imageSlideIndex - 1;
        if (newIndex < 0) newIndex = images.length - 1;
        updateImageSlide(newIndex);
      }
    }
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [images, admin, updateImageSlide, imageSlideIndex]);

  const imageZ =
    windowSize.width < 770 ? -80 - 80 + windowSize.width / 30 : -80;

  return (
    <mesh position={[0, 16.5, imageZ]} rotation={[-2.06 * Math.PI, 0, 0]}>
      <planeBufferGeometry attach="geometry" args={[108, 60.8]} />
      <meshBasicMaterial attach="material" map={selectedTexture} />
    </mesh>
  );
}
